@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
.footer-container {
	position: relative;
}

.footer-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 48px 100px;
	box-sizing: border-box;
	/* background: #000000; */
	position: absolute;
	width: 100%;
	top: -290px;
}

.rectangle {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: -15px;
}

.subscribe {
	position: relative;
	z-index: 1;
}

.about-footer {
	display: flex;
	justify-content: space-between;
	width: 30%;
}

.footer-header h2 {
	font-weight: 400;
	font-size: 24px;
	font-family: "Montserrat" sans-serif;
	color: white;
	line-height: 103.4%;
}

.footer-header input {
	width: 100%;
	border: 1px solid #ffffff;
	box-sizing: border-box;
	box-shadow: inset 11.4667px -11.4667px 11.4667px rgba(149, 149, 149, 0.1),
		inset -11.4667px 11.4667px 11.4667px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(22.9333px);
	border-radius: 10px;
	padding: 15px 0px;
	background: transparent;
	color: white;
}

.footer-header button {
	margin-top: 10px;
	background: #2c90ac;
	box-shadow: 7px 4px 25px 14px rgba(75, 23, 65, 0.1);
	color: white;
	padding: 10px 30px;
	border: none;
	border-radius: 10px;
	font-size: 15px;
	line-height: 18px;
	font-family: "Montserrat" sans-serif;
}

.about-footer p {
	font-size: 20px;
	font-weight: 400;
	font-family: "Montserrat" sans-serif;
	color: white;
	opacity: 0.5;
	letter-spacing: -0.05em;
}

.about-footer p a {
	text-decoration: none;
	color: white;
}

.about-footer .footer-heading {
	font-weight: 600;
	opacity: 1;
}

.footer-copyright {
	background-color: rgba(0, 0, 0, 1);
}

.footer-copyright p {
	text-align: center;
	color: white;
	font-size: 18px;
	font-family: "Montserrat" sans-serif;
	font-weight: 400;
	line-height: 22px;
	margin: 0px;
	padding-top: 39px;
}

.landing-designs {
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.landing-designs div {
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.landing-designs img {
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.footer-header {
		padding: 48px 50px;
	}
}

@media screen and (max-width: 850px) {
	.footer-container {
		position: unset;
	}

	.footer-header {
		position: unset;
	}

	.footer-header {
		padding: 48px 0px;
		flex-direction: column;
		width: 100%;
	}

	.about-footer {
		width: 100%;
		text-align: center;
		flex-direction: column;
		margin: 0px auto;
	}

	.rectangle {
		display: none;
	}
}
