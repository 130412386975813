.mc-header {
	padding: 100px 0px 0px 8%;
	display: flex;
	margin-bottom: 1000px;
	position: relative;
}

.mc-header-text {
	z-index: 1000;
}

.mc-img {
	width: 50%;
	overflow: hidden;
}

.mc-img img {
	width: 100%;
	position: absolute;
	top: 100px;
	left: 250px;
	overflow: hidden;
}

.mc-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
}
.mc-header p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
	/* margin-bottom: 70px; */
}

.mc-header a {
	border: none;
	background-color: rgba(32, 120, 145, 1);
	color: white;
	font-size: 16px;
	padding: 15px 40px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s all ease-out;
	display: none;
	text-decoration: none;
}

.mc-img-container {
	margin-top: 30px;
	display: flex;
}

.mc-img-container iframe {
	border-radius: 20px;
}

.mc-img-container img:first-child {
	margin-left: 0px;
}

@media screen and (max-width: 1071px) {
	.mc-img {
		width: 100%;
	}

	.mc-img img {
		width: 100%;
		height: 700px;
		top: 10%;
		left: 0;
		object-fit: cover;
		object-position: top center;
	}

	.mc-details {
		margin-top: 700px;
	}

	.mc-header {
		padding: 100px 0px 0px 0px;
		margin-bottom: 500px;
		flex-direction: column;
	}

	.mc-header-text {
		width: 100%;
	}

	.mc-header a {
		display: inline;
	}
}

@media screen and (max-width: 850px) {
	.mc-header {
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 610px) {
	.mc-header p {
		width: 100%;
	}

	.mc-header-text {
		width: 100%;
	}

	.mc-img-container {
		flex-direction: column;
		width: 100%;
	}

	.mc-img-container img {
		width: 100%;
		margin-left: 0px;
		margin-top: 20px;
	}

	.mc-img-container img:first-child {
		margin-top: 0px;
	}
}

@media screen and (max-width: 500px) {
	.mc-img-container iframe {
		width: 100%;
		height: 200px;
	}
}

@media screen and (max-width: 440px) {
	.mc-header h2 {
		width: 100%;
		font-size: 40px;
	}
}
