@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
/* html {
	scroll-behavior: smooth;
} */
.landing-container {
	background-color: rgba(0, 0, 0, 1);
	padding: 38px 100px;
}

.landing-body {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.emma-body {
	width: 50%;
	position: relative;
}

.emma-body img {
	width: 100%;
}

.landing-body h2 {
	color: white;
	width: 624px;
	font-size: 70px;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
}

.landing-body .span1 {
	font-weight: 400;
}

.landing-body .span2 {
	font-weight: 600;
}

.landing-arrow {
	width: 80px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.1);
	height: 80px;
	cursor: pointer;
	border: 1px solid white;
	box-shadow: inset 11.4667px -11.4667px 11.4667px rgba(149, 149, 149, 0.1),
		inset -11.4667px 11.4667px 11.4667px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(22.9333px);
}

.landing-arrow img {
	padding: 25px 30px;
}
.landing-design {
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.introduction {
	width: 284px;
	background-color: rgba(196, 196, 196, 0.1);
	border: 1px solid rgba(244, 173, 0, 0.3);
	box-sizing: border-box;
	box-shadow: inset 11.4667px -11.4667px 11.4667px rgba(149, 149, 149, 0.1),
		inset -11.4667px 11.4667px 11.4667px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(22.9333px);
	border-radius: 30px;
	position: absolute;
	top: 282px;
	padding: 33px 29px 29px 50px;
	left: 330px;
}

.introduction-quote {
	margin: 0px;
	padding: 0px;
	color: white;
	position: absolute;
	left: 17%;
	top: 14%;
	font-weight: 500;
	font-style: italic;
	font-size: 36px;
}

.introduction-text {
	margin: 0px;
	position: relative;
	font-weight: 500;
	font-style: italic;
	font-size: 16px;
	color: white;
	line-height: 100%;
	width: 149px;
}

.landing-design div {
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.landing-design img {
	width: 100%;
}

.landing-design1 {
	display: flex;
	width: 100%;
	position: absolute;
	margin-left: 0px;
	left: 0;
	overflow: hidden;
}

.landing-design1 div {
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.landing-design1 img {
	width: 100%;
}

.landing-about {
	background-color: rgba(0, 0, 0, 1);
	padding: 38px 0px;
	margin-bottom: 400px;
}

.landing-about-body {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0px;
}

.landing-about h2 {
	color: rgba(255, 255, 255, 1);
	font-size: 36px;
	line-height: 32.72px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
	letter-spacing: -0.05em;
	opacity: 0.5;
}

.landing-about h2 a {
	text-decoration: none;
	color: rgba(255, 255, 255, 1);
}

.landing-about .landing-heading {
	opacity: 1;
	font-weight: 600;
}

.landing-about-arrow {
	width: 80px;
	border-radius: 50%;
	height: 80px;
	cursor: pointer;
	border: 1px solid white;
	background: #207891;
	box-shadow: inset 11.4667px -11.4667px 11.4667px rgba(149, 149, 149, 0.1),
		inset -11.4667px 11.4667px 11.4667px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(22.9333px);
}

.landing-about-arrow img {
	padding: 25px 30px;
}

.video-week {
	padding: 200px 50px;
}

.video-week div {
	background-image: url(../../assets/video.png);
	background-size: cover;
	padding: 250px 0px 50px 80px;
	border-radius: 10px;
}

.video-week div h2 {
	font-weight: 700;
	font-family: "Montserrat", sans-serif;
	font-size: 48px;
	color: white;
	opacity: 1;
	margin: 0;
	line-height: 100%;
}

.video-week div button {
	background: #207891;
	border-radius: 10px;
	padding: 20px 50px;
	margin-top: 20px;
	cursor: pointer;
	color: white;
	font-size: 20px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	border: none;
}

.video-week div button a {
	text-decoration: none;
	cursor: pointer;
	color: white;
	font-size: 20px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1071px) {
	.landing-body {
		flex-direction: column;
	}

	.emma-body {
		width: 100%;
	}

	.introduction {
		display: none;
	}

	.landing-about-body {
		flex-direction: column;
	}

	.landing-about-links {
		width: 100%;
		text-align: center;
	}

	.landing-about {
		padding: 38px 20px;
	}

	.video-week {
		padding: 100px 20px;
	}

	.landing-design {
		margin-top: 20px;
	}
}

@media screen and (max-width: 850px) {
	.landing-about {
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 625px) {
	.landing-body h2 {
		width: 100%;
		font-size: 50px;
	}

	.landing-container {
		padding: 38px 50px;
	}

	.video-week div {
		padding: 250px 0px 50px 10px;
	}
}
@media screen and (max-width: 541px) {
	.landing-container {
		padding: 38px 20px;
	}

	.landing-about-img img {
		width: 100%;
	}

	.video-week div h2 {
		font-weight: 700;
		font-size: 38px;
	}
}

@media screen and (max-width: 448px) {
	.video-week div h2 {
		font-weight: 700;
		font-size: 30px;
	}
}
