.admin {
	margin: 100px auto 400px;
	/* display: flex;
	justify-content: space-between; */
}

@media screen and (max-width: 850px) {
	.admin {
		margin: 100px auto;
	}
}
