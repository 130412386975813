.brand-header {
	padding: 100px 0px;
	display: flex;
	position: relative;
	margin-bottom: 1000px;
}

.brand-text {
	width: 50%;
	z-index: 900;
}

.emma-img {
	width: 50%;
}

.emma-img img {
	width: 100%;
	position: absolute;
	top: 100px;
	left: 100px;
}

.brand-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
}
.brand-header p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
}

.brand-design {
	display: flex;
	width: 100%;
	overflow: hidden;
}

.brand-design img {
	width: 100%;
}

.brand-button {
	margin-top: 30px;
}

.brand-button a {
	border: none;
	background-color: rgba(32, 120, 145, 1);
	color: white;
	font-size: 16px;
	padding: 15px 40px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s all ease-out;
	margin-top: 50px;
	margin-left: 15px;
	text-decoration: none;
}

.brand-button a:first-child {
	margin-left: 0px;
}

.brand-button a:hover {
	color: rgba(32, 120, 145, 1);
	background-color: white;
}

.recent-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}

.recent-container iframe {
	width: 80%;
	border-radius: 20px;
}

.last iframe {
	width: 90%;
}

@media screen and (max-width: 1071px) {
	.brand-header {
		width: 95%;
		margin: 0px auto 500px;
		flex-direction: column;
	}

	.brand-text {
		width: 100%;
	}

	.emma-img {
		width: 100%;
	}

	.emma-img img {
		width: 100%;
		height: 700px;
		object-fit: cover;
		object-position: top center;
		top: 15%;
		left: 0;
	}

	.brand-details {
		margin-top: 700px;
	}
}

@media screen and (max-width: 850px) {
	.brand-header {
		margin: 0px auto 100px;
	}
}

@media screen and (max-width: 610px) {
	.emma-img img {
		height: 500px;
		top: 20%;
	}

	.brand-details {
		margin-top: 550px;
	}
}

@media screen and (max-width: 511px) {
	.brand-header h2 {
		font-size: 54px;
	}

	.recent-container {
		flex-direction: column;
	}

	.recent-container iframe {
		width: 100%;
		margin-top: 20px;
		height: 200px;
	}
}

@media screen and (max-width: 495px) {
	.brand-button {
		display: flex;
		flex-direction: column;
	}

	.brand-button a {
		margin-left: 0px;
		display: inline;
		text-align: center;
	}
}

@media screen and (max-width: 431px) {
	.brand-header h2 {
		font-size: 44px;
	}

	.emma-img img {
		top: 17%;
	}
}

@media screen and (max-width: 351px) {
	.brand-header h2 {
		font-size: 40px;
	}
}
