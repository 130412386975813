.music-header {
	padding: 100px 0px 0px 0px;
	display: flex;
	position: relative;
	justify-content: space-between;
	margin-bottom: 350px;
}

.music-header-text {
	width: 50%;
	z-index: 900;
}

.music-emma {
	width: 50%;
}

.music-emma img {
	position: absolute;
	top: 100px;
	left: 50%;
	width: 50%;
}

.music-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	width: 640px;
}

.music-header p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
}

.music {
	display: flex;
}

.music div {
	width: 800px;
	height: 74px;
	background-color: white;
	padding: 0px 20px;
	margin-left: 24px;
	margin-top: 25px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.music div img {
	width: 100%;
}

.music div:first-child {
	margin-left: 0px;
}

.musics {
	display: flex;
}

.musics div {
	width: 161px;
	height: 74px;
	background-color: white;
	margin-left: 24px;
	margin-top: 25px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.musics div:first-child {
	margin-left: 0px;
}
@media screen and (max-width: 1071px) {
	.music-header {
		width: 95%;
		margin: 0px auto 300px;
	}

	.music-header-text {
		width: 100%;
	}

	.music-emma {
		width: 100%;
	}

	.music-emma img {
		left: 0;
		width: 100%;
		top: 12%;
		height: 700px;
		object-fit: cover;
		object-position: top center;
	}

	.music-details {
		margin-top: 700px;
	}
}

@media screen and (max-width: 850px) {
	.music-header {
		margin: 0px auto;
		flex-direction: column;
	}

	.music-header-text .apple {
		width: 100%;
	}

	.music-header h2 {
		width: 100%;
	}
}

@media screen and (max-width: 610px) {
	.music-details {
		margin-top: 500px;
	}

	.music-emma img {
		top: 20%;
		height: 500px;
	}
}

@media screen and (max-width: 500px) {
	.music {
		flex-direction: column;
	}

	.music div {
		width: 100%;
		box-sizing: border-box;
		margin-left: 0px;
		height: fit-content;
	}

	.music-emma img {
		height: 450px;
	}

	.music-details {
		margin-top: 800px;
	}
}
