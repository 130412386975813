.online-header {
	padding: 100px 0px 0px 0px;
	display: flex;
	position: relative;
	margin-bottom: 1000px;
}

.online-header-text {
	z-index: 11111;
}

.online-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	width: 640px;
}
.online-header p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
	width: 347px;
	margin-bottom: 70px;
}

.online-img-container {
	margin-top: 30px;
	display: flex;
	width: 95%;
	justify-content: space-between;
}

.online-iframe {
	position: relative;
	width: 45%;
	height: 200px;
	border-radius: 20px;
}

.online-iframe div {
	position: absolute;
	width: 100%;
	height: 100%;
}

.online-iframe iframe {
	width: 100%;
	height: 100%;
	border-radius: 20px;
}

.online-img-container img {
	border-radius: 10px;
	margin-left: 30px;
}

.online-img-container img:first-child {
	margin-left: 0px;
}

.online-pic img {
	width: 100%;
	left: 200px;
	top: 100px;
	position: absolute;
}

@media screen and (max-width: 1071px) {
	.online-pic {
		width: 100%;
		height: fit-content;
	}

	.online-pic img {
		width: 100%;
		height: 900px;
		top: 15%;
		left: 0;
		object-fit: cover;
		object-position: top center;
	}

	.online-details {
		margin-top: 950px;
	}

	.online-header {
		width: 95%;
		margin: 0px auto 500px;
	}

	.online-header-text {
		width: 100%;
	}
}

@media screen and (max-width: 850px) {
	.online-header {
		margin: 0px auto 100px;
	}

	.online-img-container {
		width: 100%;
	}
}

@media screen and (max-width: 612px) {
	.online-img-container {
		display: flex;
		flex-direction: column;
	}

	.online-img-container img {
		margin-left: 0px;
	}

	.online-iframe {
		width: 95%;
		margin: 0px auto;
		margin-top: 10px;
	}

	.online-img-container img:first-child {
		margin-top: 0px;
	}

	.online-header p {
		width: 100%;
	}

	.online-pic img {
		height: 500px;
		top: 10%;
	}

	.online-details {
		margin-top: 550px;
	}
}

@media screen and (max-width: 471px) {
	.online-header h2 {
		font-size: 50px;
	}
}

@media screen and (max-width: 368px) {
	.online-header h2 {
		font-size: 45px;
	}
}

@media screen and (max-width: 331px) {
	.online-header h2 {
		font-size: 40px;
	}
}
