.birthday-header {
	padding: 100px 0px 0px 0px;
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 700px;
}

.birthday-header-body {
	z-index: 900;
}

.birthday-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	margin-bottom: 30px;
	width: 100%;
}

.birthday-pic img {
	left: 200px;
	position: absolute;
	top: 100px;
}

.shoutout-body {
	display: flex;
	/* justify-content: space-between; */
	margin-top: 40px;
}

.shoutout-body:first-child {
	margin-top: 0px;
}

.shoutout-body .shoutout-container {
	margin-left: 40px;
}

.shoutout-body .shoutout-container:first-child {
	margin-left: 0px;
}

.birthday-buttons {
	display: flex;
	margin-top: 81px;
}

.birthday-buttons a {
	color: white;
	font-weight: 500;
	font-size: 20px;
	background-color: rgba(32, 120, 145, 1);
	padding: 23px 50px;
	line-height: 100%;
	border-radius: 10px;
	border: none;
	margin-left: 40px;
	text-decoration: none;
}

.birthday-buttons a:first-child {
	margin-left: 0px;
}

@media screen and (max-width: 1071px) {
	.birthday-header {
		width: 95%;
		margin: 0px auto 500px;
	}

	.birthday-header-body {
		width: 100%;
	}

	.shoutout-body {
		width: 100%;
	}

	.shoutout-details {
		margin-top: 950px;
	}

	.birthday-pic img {
		left: 0;
		top: 10%;
		width: 100%;
		height: 900px;
		object-fit: cover;
		object-position: top center;
	}
}

@media screen and (max-width: 850px) {
	.birthday-header-body {
		width: 100%;
	}
	.birthday-header {
		margin: 0px auto 100px;
	}
}

@media screen and (max-width: 674px) {
	.birthday-buttons {
		flex-direction: column;
	}

	.birthday-buttons a {
		margin-left: 0px;
		margin-top: 20px;
	}
}

@media screen and (max-width: 660px) {
	.shoutout-body {
		flex-direction: column;
	}

	.birthday-pic img {
		top: 7%;
		height: 500px;
	}

	.shoutout-details {
		margin-top: 500px;
	}

	.shoutout-body .shoutout-container {
		margin-left: 0px;
	}

	.birthday-header h2 {
		font-size: 50px;
	}
}

@media screen and (max-width: 500px) {
	.birthday-header h2 {
		font-size: 40px;
	}

	.birthday-buttons a {
		width: 100%;
		text-align: center;
		box-sizing: border-box;
	}

	.shoutout-details {
		margin-top: 550px;
	}
}

@media screen and (max-width: 352px) {
	.birthday-header h2 {
		font-size: 35px;
	}
}
