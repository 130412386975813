html {
	scroll-behavior: smooth;
}

.about-header {
	padding: 100px 0px 0px 0px;
	display: flex;
	position: relative;
	margin-bottom: 700px;
}

.about-header-text {
	width: 40%;
}

.about-img {
	width: 50%;
}

.about-img img {
	width: 80%;
	left: 350px;
	top: 100px;
	position: absolute;
}

.about-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	width: 600px;
}
.about-header h2 span {
	font-weight: 300;
}

.about-details {
	padding: 73px 80px 100px 55px;
	background: rgba(0, 0, 0, 0.1);
	box-shadow: inset 79.1333px -79.1333px 79.1333px rgba(0, 0, 0, 0.1),
		inset -79.1333px 79.1333px 79.1333px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(158.267px);
	border-radius: 30px;
	width: 100%;
	position: relative;
	z-index: 999;
}

.about-details p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
}

.socials {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 200px;
	margin-top: 30px;
}

.socials img {
	cursor: pointer;
}

@media screen and (max-width: 1071px) {
	.about-header {
		flex-direction: column;
	}
	.about-img {
		width: 100%;
	}

	.about-img img {
		left: 0;
		top: 300px;
		height: 700px;
		width: 100%;
		object-fit: cover;
		object-position: top left;
	}

	.about-header {
		padding: 100px 0px 0px 20px;
		margin: 0px auto 500px;
		width: 100%;
	}

	.about-details {
		margin: 750px auto 0px;
		width: 80%;
	}

	.about-header-text {
		width: 100%;
	}
}

@media screen and (max-width: 850px) {
	.about-header {
		margin: 0px auto 100px;
	}
}

@media screen and (max-width: 610px) {
	.about-details {
		padding: 20px;
		margin: 500px auto 0px;
	}

	.about-header-text {
		width: 100%;
	}
	.about-header {
		padding: 100px 0px 0px 0px;
		width: 95%;
	}

	.about-header h2 {
		width: 80%;
		font-size: 40px;
		overflow: hidden;
		padding-left: 20px;
	}

	.about-details p {
		text-align: left;
	}

	.about-img img {
		left: 0;
		top: 200px;
		width: 100%;
		height: 500px;
	}
}

@media screen and (max-width: 448px) {
	.about-header h2 {
		font-size: 30px;
		width: 100%;
	}

	.about-details {
		padding: 20px;
		margin: 529px auto 0px;
	}
}
