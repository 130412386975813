.contact-header {
	padding: 100px 0px 400px 8%;
	display: flex;
	justify-content: space-between;
}

.contact-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	margin: 0px;
	margin-bottom: 30px;
}

.mail-div a {
	text-decoration: none;
	color: white;
	display: inline-block;
	margin-left: 12px;
	font-size: 16px;
	color: rgba(32, 120, 145, 1);
	font-weight: 400;
	line-height: 20px;
}

.social-contact {
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
}

.social-contact > div {
	width: 20%;
}

.social-contact img {
	/* width: 100%; */
	display: block;
	cursor: pointer;
	margin-top: 0px;
}

.event {
	margin-bottom: 40px;
	display: flex;
}

.event-test {
	width: 200px;
	position: relative;
	overflow: hidden;
	height: 50px;
}
.form-container {
	width: 500px;
	position: relative;
	overflow: hidden;
	height: 50px;
	margin-bottom: 40px;
}

.form-containers {
	width: 500px;
	position: relative;
	overflow: hidden;
	height: 150px;
	margin-bottom: 40px;
}
.form-containers input {
	width: 100%;
	height: 100%;
	border: none;
	background-color: transparent;
	color: #ffffff;
	outline: none;
	font-size: 20px;
}

.form-containers label {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	color: white;
	height: 100%;
	pointer-events: none;
	font-weight: 300;
	font-size: 18px;
	border-bottom: 1px solid #ffffff;
}

.form-containers label::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0px;
	bottom: -1px;
	border-bottom: 3px solid #ffffff;
	transform: translateX(-100%);
}

.content-names {
	position: absolute;
	bottom: 110px;
	left: 0px;
	transition: all 0.3s ease;
}

.form-containers input:focus + .label-name .content-names,
.form-containers input:valid + .label-name .content-names {
	transform: translateY(-100%);
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}

.form-containers input:focus + .label-name::after,
.form-containers input:valid + .label-name::after {
	transform: translateX(0%);
}

.form-container input {
	width: 100%;
	height: 100%;
	border: none;
	background-color: transparent;
	color: #ffffff;
	outline: none;
	font-size: 20px;
	padding-top: 12px;
}

.form-container label {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	color: white;
	height: 100%;
	pointer-events: none;
	font-weight: 300;
	font-size: 18px;
	border-bottom: 1px solid #ffffff;
}

.form-container label::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0px;
	bottom: -1px;
	border-bottom: 3px solid #ffffff;
	transform: translateX(-100%);
}

.content-name {
	position: absolute;
	bottom: 5px;
	left: 0px;
	transition: all 0.3s ease;
}

.form-container input:focus + .label-name .content-name,
.form-container input:valid + .label-name .content-name {
	transform: translateY(-100%);
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}

.form-container input:focus + .label-name::after,
.form-container input:valid + .label-name::after {
	transform: translateX(0%);
}

.event-test input {
	width: 100%;
	height: 100%;
	border: none;
	background-color: rgba(0, 0, 0, 1);
	padding-top: 10px;
	color: #ffffff;
	outline: none;
	font-size: 20px;
}

.event-test label {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	color: white;
	height: 100%;
	pointer-events: none;
	font-weight: 300;
	font-size: 18px;
	border-bottom: 1px solid #ffffff;
}

.event-test label::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0px;
	bottom: -1px;
	border-bottom: 3px solid #ffffff;
	transform: translateX(-100%);
}

.content-name {
	position: absolute;
	bottom: 15px;
	left: 0px;
	transition: all 0.3s ease;
}

.event-test input:focus + .label-name .content-name,
.event-test input:valid + .label-name .content-name {
	transform: translateY(-100%);
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}

.event-test input:focus + .label-name::after,
.event-test input:valid + .label-name::after {
	transform: translateX(0%);
}

.event select {
	appearance: none;
	width: 290px;
	margin-left: 20px;
	border: none;
	border-bottom: 1px solid #ffffff;
	background-color: transparent;
	color: white;
	font-weight: 300;
	line-height: 26.58px;
	font-size: 18px;
}

.contact-header button {
	background-color: rgba(32, 120, 145, 1);
	color: white;
	padding: 20px 60px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	border: none;
	cursor: pointer;
}

@keyframes animate {
	from {
		margin-top: 10px;
	}

	to {
		margin-top: 0px;
	}
}

.social-contact img:hover {
	animation-name: animate;
	animation-duration: 2s;
}

@media screen and (max-width: 1024px) {
	.contact-header {
		padding: 100px 0px 300px 0%;
		flex-direction: column;
		width: 90%;
		margin: 0px auto;
	}

	.contact-form {
		margin-top: 50px;
	}

	.form-container {
		width: 100%;
	}
	.contact-header {
		padding: 100px 0px 300px 0%;
		flex-direction: column;
		width: 90%;
		margin: 0px auto;
	}

	.form-containers {
		width: 100%;
	}

	.event-test {
		width: 40%;
	}

	.event select {
		width: 60%;
	}
}

@media screen and (max-width: 850px) {
	.contact-header {
		padding: 100px 0px 0px 0%;
	}
}

@media screen and (max-width: 393px) {
	.contact-header h2 {
		font-size: 50px;
	}
}
