.shoutout-container {
	padding: 55px 57px 20px 57px;
	background: rgba(0, 0, 0, 0.1);
	box-shadow: inset 79.1333px -79.1333px 79.1333px rgba(0, 0, 0, 0.1),
		inset -79.1333px 79.1333px 79.1333px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(158.267px);
	border-radius: 30px;
	width: 150px;
}

.shoutout-container h3 {
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	text-align: center;
	color: #207891;
}

.shoutout-container p {
	font-size: 14px;
	font-weight: 400;
	line-height: 200%;
	text-align: center;
	color: #fff;
	width: 180px;
	margin: auto;
}

.shoutout-container a p {
	font-size: 20px;
	background-color: #207891;
	color: white;
	padding: 10px 0px;
	width: 100%;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 20px;
}

.shoutout-container .acapella {
	display: none;
}

.shoutout-container a {
	text-decoration: none;
}

@media screen and (max-width: 1071px) {
	.shoutout-container {
		width: 90%;
	}
}

@media screen and (max-width: 660px) {
	.shoutout-container {
		width: 80%;
		margin-top: 20px;
		margin-right: 0px;
	}
}

@media screen and (max-width: 590px) {
	.shoutout-container {
		width: 70%;
	}
}
