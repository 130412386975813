.family-header {
	padding: 100px 0px 0px 0px;
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	margin-bottom: 500px;
	position: relative;
}

.family-header h2 {
	color: white;
	font-weight: 600;
	font-size: 64px;
	line-height: 100%;
	letter-spacing: -0.05em;
	width: 640px;
}
.family-header p {
	color: white;
	line-height: 213.4%;
	text-align: justify;
	font-size: 18px;
	font-weight: 400;
}

.family-image img {
	width: 100%;
}

.family-image {
	margin-top: 100px;
}

.family-img-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 20px;
}

.family-img-container iframe {
	border-radius: 20px;
}

.family-img-container img:first-child {
	margin-left: 0px;
}

@media screen and (max-width: 1071px) {
	.family-image {
		width: 100%;
	}

	.family-image img {
		top: 20%;
		left: 0;
		width: 100%;
		position: absolute;
	}

	.family-details {
		margin-top: 700px;
	}
}

@media screen and (max-width: 1024px) {
	.family-header {
		width: 95%;
		margin: 0px auto 500px;
		flex-direction: column;
	}

	.family-img-container {
		width: 70%;
		margin: 20px auto;
	}

	.family-img-container iframe {
		width: 45%;
	}
}

@media screen and (max-width: 850px) {
	.family-header {
		margin: 0px auto 100px;
	}

	.family-details {
		margin-top: 600px;
	}
}

@media screen and (max-width: 750px) {
	.family-image img {
		top: 25%;
	}

	.family-details {
		margin-top: 550px;
	}

	.family-img-container {
		width: 90%;
	}

	.family-img-container iframe {
		width: 47%;
	}
}

@media screen and (max-width: 623px) {
	.family-image img {
		top: 15%;
	}

	.family-header-text {
		width: 100%;
	}

	.family-header h2 {
		width: 100%;
	}

	.family-header p {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.family-details {
		margin-top: 500px;
	}
}

@media screen and (max-width: 500px) {
	.family-details {
		margin-top: 400px;
	}

	.family-img-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.family-img-container iframe {
		width: 100%;
		margin-left: 0px;
		margin-top: 20px;
		height: 200px;
	}
}

@media screen and (max-width: 450px) {
	.family-image img {
		top: 20%;
	}
}

@media screen and (max-width: 380px) {
	.family-details {
		margin-top: 300px;
	}
}
