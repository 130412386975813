.band-members-header {
  padding: 100px 0px 0px 0px;
  display: flex;
  position: relative;
  margin-bottom: 700px;
}

.band-members-text {
  width: 40%;
}

.band-members-img {
  width: 50%;
}

.band-members-img img {
  width: 80%;
  left: 350px;
  top: 100px;
  position: absolute;
}

.band-members-header h2 {
  color: white;
  font-weight: 600;
  font-size: 64px;
  line-height: 100%;
  letter-spacing: -0.05em;
  width: 600px;
}
.band-members-header h2 span {
  font-weight: 300;
}

.band-members-details {
  padding: 73px 80px 100px 55px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 79.1333px -79.1333px 79.1333px rgba(0, 0, 0, 0.1), inset -79.1333px 79.1333px 79.1333px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(158.267px);
  border-radius: 30px;
  width: 100%;
  position: relative;
  z-index: 999;
}

.band-members-details p {
  color: white;
  line-height: 213.4%;
  text-align: justify;
  font-size: 18px;
  font-weight: 400;
}

.button-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button-actions button {
  border: none;
  background-color: rgba(32, 120, 145, 1);
  color: white;
  font-size: 16px;
  padding: 15px 0px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s all ease-out;
  margin-top: 20px;
  width: 49%;
  cursor: pointer;
  text-align: center;
  z-index: 20;
}

@media screen and (max-width: 1071px) {
  .band-members-img {
    width: 100%;
  }

  .band-members-img img {
    left: 0;
    top: 300px;
    height: 700px;
    width: 100%;
    object-fit: cover;
    object-position: top left;
  }

  .band-members-header {
    padding: 100px 0px 0px 20px;
    margin: 0px auto 500px;
    width: 100%;
    flex-direction: column;
  }

  .band-members-details {
    margin: 750px auto 0px;
    width: 80%;
  }

  .band-members-text {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .band-members-header {
    margin: 0px auto 100px;
  }
}

@media screen and (max-width: 610px) {
  .about-details {
    padding: 20px;
    margin: 500px auto 0px;
  }

  .band-members-details {
    width: 100%;
  }
  .band-members-header {
    padding: 100px 0px 0px 0px;
    width: 95%;
  }

  .band-members-header h2 {
    width: 80%;
    font-size: 40px;
    overflow: hidden;
    padding-left: 20px;
  }

  .band-members-details p {
    text-align: left;
  }

  .band-members-img img {
    left: 0;
    top: 200px;
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 448px) {
  .band-members-header h2 {
    font-size: 30px;
    width: 100%;
  }

  .band-members-details {
    padding: 20px;
    margin: 529px auto 0px;
  }
}

@media screen and (max-width: 300px) {
  .button-actions {
    flex-direction: column;
  }

  .button-actions button {
    width: 100%;
  }
}
