.video-container {
	width: 85%;
	margin: 100px auto;
	height: 100%;
	padding: 0px 0px 125px;
}

.video-text {
	width: 100%;
	height: 100%;
}

.video-text h2 {
	font-size: 40px;
	font-weight: 400;
	color: white;
	line-height: 100%;
}

.video-text p {
	color: white;
	font-weight: 400;
	line-height: 200%;
	width: 545px;
	font-size: 20px;
}

.input-link form {
	display: flex;
}

.input-link input {
	border: 1px solid white;
	border-radius: 10px;
	padding: 20px 104px 20px 30px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	opacity: 0.5;
	outline: none;
	color: white;
	background-color: #000000;
}

.input-link button {
	border: none;
	padding: 20px 50px;
	background-color: #207891;
	border-radius: 10px;
	color: white;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	margin-left: 20px;
	cursor: pointer;
}

@media screen and (max-width: 1071px) {
	.video-container {
		width: 100%;
		padding: 0px 0px 600px;
	}
}

@media screen and (max-width: 990px) {
	.video-text {
		width: 100%;
		margin-left: 0px;
	}

	.video-text h2 {
		text-align: center;
	}

	.video-text p {
		width: 100%;
	}

	.input-link form {
		width: 100%;
		justify-content: space-between;
	}

	.input-link form input {
		width: 70%;
	}
}

@media screen and (max-width: 400px) {
	.input-link form {
		flex-direction: column;
	}

	.input-link form input {
		box-sizing: border-box;
		width: 95%;
	}

	.input-link form button {
		margin-top: 20px;
		width: 95%;
		margin-left: 0px;
	}
}
